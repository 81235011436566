.container {
    box-sizing: content-box;
    position: relative;

    .acronym {
        position: absolute;
        bottom: 38%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: Roboto, sans-serif;
        font-weight: 900;
    }

    .shape {
        margin: 10%;
        width: 80%;
        height: 80%;
    }

    .icon {
        position: absolute;
        bottom: 45%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .endorsedWrapper {
        position: absolute;
        bottom: 25%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .endorsed {
            background-color: #d91e18;
            font-family: Roboto, sans-serif;
        }
    }
}
